// Import global styles
@import './variables';
@import './base';

// Root variables
:root {
  --mono-font: 'Commit Mono', 'SF Mono', monospace;
  --vh: 1vh;
}

html {
  height: 100vh;
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--mono-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background-color;
  color: $color-white;
  min-height: 100vh;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 0.06em;
  text-transform: uppercase;

  @media (min-width: 769px) {
    font-size: 14px;
  }
}

// Reset heading styles
h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}