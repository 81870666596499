@import 'variables';

/* Reset & Base Styles */
*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100vh;
  overflow: hidden;
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  font-family: var(--mono-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background-color;
  color: $color-white;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  text-rendering: optimizeSpeed;

  @media (min-width: 769px) {
    font-size: 14px;
  }
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
  line-height: 120%;
  font-family: var(--mono-font);
  letter-spacing: 0.5px;
  color: white;
}

/* Lists */
ul, ol {
  list-style: none;
}

/* Media */
img {
  max-width: 100%;
  display: block;
}

/* Form elements */
input, button, textarea, select {
  font: inherit;
}

/* Common Components */

// Toast notifications
.error-toast,
.success-toast {
  position: fixed;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  padding: 16px 24px;
  border-radius: 8px;
  z-index: 1000;
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  max-width: 90%;
  width: auto;
  text-align: center;
  animation: slideDown 0.3s ease-out;
  font-family: var(--mono-font);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
}

.error-toast {
  background: rgba(255, 68, 68, 0.95);
  color: white;
}

.success-toast {
  background: rgba(40, 167, 69, 0.95);
  color: white;
}

// Loading states
.loading {
  text-align: center;
  padding: 40px;
  color: rgba(255, 255, 255, 0.6);
  font-family: var(--mono-font);
  font-size: 16px;
  letter-spacing: 0.5px;
}

// Common page layout
.page-container {
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  position: relative;
  color: $color-white;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 4rem 0;
  overflow-y: auto;
  box-sizing: border-box;
  @include font-tiny;

  @media (min-width: 769px) {
    @include font-small;
  }

  @media (max-width: 768px) {
    padding: 6rem 0;
  }
}

// Common content frame
.content-frame {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  box-sizing: border-box;
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: $border-radius;
  padding: 3rem;

  @media (max-width: 768px) {
    width: auto;
    margin: 0;
    padding: 1.5rem;
  }
}

// Common link styles
.external-link {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  font-family: var(--mono-font);
  font-size: 0.9em;
  transition: all 0.3s ease;

  &:hover {
    color: $color-white;

    .link-icon {
      transform: translate(2px, -2px);
    }
  }

  .link-icon {
    font-size: 1em;
    transition: transform 0.3s ease;
  }
}

// Common back link
.back-link {
  display: block;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  font-family: var(--mono-font);
  font-size: 0.9em;
  margin: 4rem auto 0;
  padding: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: $border-radius;
  transition: all 0.3s ease;
  background: rgba(9, 17, 21, 0.95);
  backdrop-filter: blur(10px);
  max-width: 800px;

  &:hover {
    color: $color-white;
    border-color: rgba(255, 255, 255, 0.2);
  }
}

// Common image container styles
.image-container {
  position: relative;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .image-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 8px 16px;
    font-size: 0.9em;
    line-height: 1.4;
    text-align: center;
    opacity: 1;
  }
}

/* Animations */
@keyframes slideDown {
  from {
    transform: translate(-50%, -100%);
    opacity: 0;
  }
  to {
    transform: translate(-50%, 0);
    opacity: 1;
  }
}

/* Accessibility */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}