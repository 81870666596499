@import 'styles/variables';

// Reset heading styles
h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}

html {
  height: 100vh;
  overflow: hidden; // Prevent body-level scrolling
}

// Set SF Mono as the default font for everything
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background-color;
  font-family: var(--mono-font);
  font-style: normal;
  font-weight: 400;
  font-size: 12px; // Base size for mobile
  line-height: 120%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  margin: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden; // Prevent body-level scrolling

  @media (min-width: 769px) {
    font-size: 14px; // Base size for desktop
  }

  * {
    font-family: inherit;
  }
}

ul {
  margin: 0;
  padding: 0;
}

/* Use a CSS variable for viewport height */
:root {
  --vh: 1vh;
}

/* Add this new class */
.app-container {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  overflow: hidden;

  // Main site styles (non-rental)
  &:not(.rental-app) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;

    .main-content {
      height: 100%;
      width: 100%;
      overflow: hidden;

      // Allow scrolling only in specific pages that need it
      &:has(.event-page),
      &:has(.about-page) {
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
      }
    }
  }

  // Rental app styles
  &.rental-app {
    position: relative;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    .main-content {
      min-height: 100%;
    }
  }
}
