@import 'styles/variables';

.splash-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  cursor: var(--enter-cursor) 14 4, auto;
  background-color: $color-dark; // Add default background color
}

.splash-scroll-container {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  -webkit-overflow-scrolling: touch;
  pointer-events: all; // Allow clicks to pass through
}

.splash-scroll-container::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

.splash-background {
  display: inline-block;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  pointer-events: none; // Allow clicks to pass through
  background-color: $color-dark; // Add default background color
  will-change: background-image;
}

.splash-background::before {
  content: "";
  display: block;
  width: 100%;
  aspect-ratio: 4 / 1;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

.splash-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5vh 10vw 20vh;
  box-sizing: border-box;
  z-index: 11;

  @media (max-width: 768px) {
    padding: 5vh 5vw 10vh;
  }
}

/* Add pointer-events back to the children of splash-content */
.splash-content .splash-enter-button{
  pointer-events: auto;
}

.splash-mask-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  pointer-events: none;
}

.splash-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  clip-path: inset(20% 0% 20% 50%);
  transition: clip-path 0.5s ease-in-out;
}

.splash-mask.entering {
  clip-path: inset(0% 0% 0% 100%);
}

.splash-glass-effect {
  position: absolute;
  top: -5%;
  left: -5%;
  width: 110%;
  height: 110%;
  background: rgba(217, 217, 217, 0.01);
  backdrop-filter: blur(7.5px);
  -webkit-backdrop-filter: blur(7.5px);
  transform: translateZ(0);
  will-change: backdrop-filter;
  pointer-events: none;
  z-index: 10;
  -webkit-mask:
    url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none"><rect x="45" y="20" width="10" height="60" fill="black"/></svg>') 0/100% 100%,
    linear-gradient(#fff,#fff);
  -webkit-mask-composite: destination-out;
  mask:
    url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="none"><rect x="45" y="20" width="10" height="60" fill="black"/></svg>') 0/100% 100%,
    linear-gradient(#fff,#fff);
  mask-composite: exclude;
  transition: -webkit-mask 0.5s ease-in-out, mask 0.5s ease-in-out;
}

.splash-glass-effect::after {
  content: '';
  position: absolute;
  top: 19.8%;
  left: 45%;
  width: 10%;
  height: 60.4%;
  box-shadow: 0 0 0 0.5px #FFFFFF;
  pointer-events: none;
}

.splash-glass-effect.entering {
  -webkit-mask: linear-gradient(#fff,#fff);
  mask: linear-gradient(#fff,#fff);
}

.splash-logo-name {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.splash-logo {
  width: 32px;
  height: 19px;
}

.splash-title {
  @include font-logo;
  text-align: center;
  color: $color-white;
  font-size: 55px; // Desktop size
  margin-block-start: 20px;
  margin-block-end: 0; // Reset default margin if needed

  @media (max-width: 768px) {
    font-size: 7vw; // Mobile size
  }
}

.splash-text-container {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 54%;
}

.splash-intro-text {
  @include font-tiny; // Default for mobile

  @media (min-width: 769px) {
    @include font-small; // Desktop
  }
  text-align: left;
  color: $color-white;
  margin-bottom: 20px;
}

.splash-enter-button {
  @include font-tiny; // Default for mobile

  @media (min-width: 769px) {
    @include font-small; // Desktop
  }
  background: none;
  border: none;
  color: $color-white;
  cursor: pointer;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.8;
  }

  .slash-icon {
    margin-left: 5px;
    font-size: 1.2em;
  }
}

.snap-points {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.snap-point {
  position: absolute;
  width: 1px;
  height: 57.28%; /* 412.98 / 721 */
  top: 20.52%; /* 147.98 / 721 */
  // background-color: rgba(255, 255, 255, 0.5); // Optional: for debugging
}

// Show the enter button on all devices
.splash-enter-button {
  display: flex;
}

// Media query for mobile devices
@media (max-width: 768px) {
  .splash-glass-effect {
    left: -60%;
    width: 220%;
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
