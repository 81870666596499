@import 'styles/variables';
@import 'styles/common';

// Override heading styles
h1, h2, h3, h4, h5, h6 {
  line-height: 120% !important;
}

.event-page {
  width: 100vw;
  height: 100vh;
  background-color: cover;
  background-position: center;
  position: relative;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 6rem 2rem 0;

  @media (max-width: 768px) {
    padding: 6rem 1rem 0;
  }
}

.event-content {
  box-sizing: border-box;
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  backdrop-filter: blur(8px);
  background: rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    padding: 1.5rem;
    margin-bottom: 1rem;
  }
}

.event-gallery {
  box-sizing: border-box;
  width: 100%;
  position: relative;
  margin: 1rem 0;
  align-self: start;

  .image-container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: transparent;
    border-radius: 4px;

    img {
      width: 100%;
      height: auto;
      display: block;
      object-fit: contain;
      border-radius: 4px;
    }

    .image-caption {
      width: 100%;
      padding: 8px 16px;
      font-size: 0.8em;
      line-height: 1.4;
      text-align: center;
      color: rgba(255, 255, 255, 0.6);
      font-family: var(--mono-font);

      &::before {
        content: '© ';
      }
    }
  }

  .event-swiper {
    .swiper-wrapper {
      align-items: flex-start;
      transition-property: transform, height !important;
    }

    .swiper-pagination {
      position: absolute;
      bottom: 2.5rem;
      left: 0;
      right: 0;
      z-index: 10;
      display: flex;
      justify-content: center;
      gap: 4px;
      margin: 0;
      padding: 0;
      background: none;
      pointer-events: none;

      .swiper-pagination-bullet {
        width: 6px;
        height: 6px;
        background: rgba(255, 255, 255, 0.5);
        opacity: 1;
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        cursor: pointer;
        pointer-events: auto;

        &-active {
          background: white;
          width: 12px;
          border-radius: 3px;
        }
      }
    }
  }
}

.event-navigation {
  position: fixed;
  width: 100%;
  max-width: calc(1140px + 400px); /* Main content width + space for nav cards */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  box-sizing: border-box;
  z-index: 5;

  @media (max-width: 1400px) {
    max-width: 100%;
    padding: 0 2rem;
  }

  @media (max-width: 768px) {
    position: relative;
    width: 100%;
    max-width: 768px;
    margin: 0 auto 2rem;
    top: auto;
    left: auto;
    transform: none;
    padding: 0;
  }

  .event-nav-link {
    pointer-events: auto;
    width: 200px;
    opacity: 0.7;
    transition: all 0.3s ease;
    text-decoration: none;
    color: $color-white;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding: 0.75rem;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    backdrop-filter: blur(8px);
    background: rgba(0, 0, 0, 0.2);

    @media (max-width: 768px) {
      width: 140px;
      gap: 0.5rem;
      padding: 0.5rem;
    }

    &:hover {
      opacity: 1;
      border-color: rgba(255, 255, 255, 0.3);
      background: rgba(0, 0, 0, 0.3);
    }

    &.prev {
      margin-right: auto;
      text-align: right;

      .nav-content {
        text-align: right;
      }
    }

    &.next {
      margin-left: auto;
      text-align: left;

      .nav-content {
        text-align: left;
      }
    }

    .nav-image {
      width: 100%;
      height: 120px;
      flex-shrink: 0;
      border-radius: 4px;
      overflow: hidden;
      background: rgba(255, 255, 255, 0.05);

      @media (max-width: 768px) {
        height: 80px;
      }

      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        border-radius: 4px;
      }
    }

    .nav-content {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      @media (max-width: 768px) {
        gap: 0.25rem;
      }
    }

    .nav-label {
      @include font-tiny;
      text-transform: uppercase;
      font-family: var(--mono-font);
      letter-spacing: 0.05em;
      opacity: 0.7;
    }

    .nav-title {
      @include font-tiny;
      color: $color-white;
    }

    .nav-date {
      @include font-tiny;
      opacity: 0.7;
      font-family: var(--mono-font);
      display: block;
    }
  }
}

.event-header {
  box-sizing: border-box;
}

.event-description {
  @include font-tiny;
  line-height: 1.6;
  white-space: pre-line;
  color: rgba(255, 255, 255, 0.8);
  box-sizing: border-box;
  width: 100%;
}

.event-metadata {
  box-sizing: border-box;
  width: 100%;

  .event-date-location {
    display: flex;
    gap: 0.5rem;
    @include font-tiny;
    color: rgba(255, 255, 255, 0.8);
    font-family: var(--mono-font);
  }

  .event-title {
    @include font-large;
    font-weight: normal;
    color: $color-white;
    text-decoration: none;
    transition: color 0.3s ease;

    @media (max-width: 768px) {
      font-size: 18px;
      margin: 0.25rem 0 0.75rem;
    }
  }

  .event-subtitle {
    @include font-tiny;
    font-style: italic;
    color: rgba(255, 255, 255, 0.8);
    line-height: 1.4;
    margin-top: 0.25em;
  }
}

.event-link {
  @extend .external-link;
  margin-top: 1rem;
  @include font-tiny;
  opacity: 0.8;
  align-self: flex-start;
}

.event-details {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
  font-family: var(--mono-font);
  @include font-tiny;

  .event-season {
    color: rgba(255, 255, 255, 0.7);
    padding: 0.25rem 0.5rem;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 3px;
    background: rgba(255, 255, 255, 0.05);
  }

  .event-garments {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
  }

  .garment-tag {
    color: rgba(255, 255, 255, 0.9);
    padding: 0.25rem 0.5rem;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 3px;
    background: rgba(255, 255, 255, 0.1);
    transition: all 0.2s ease;

    &:hover {
      background: rgba(255, 255, 255, 0.15);
      border-color: rgba(255, 255, 255, 0.4);
    }
  }
}

.event-press-mentions {
  margin-top: 2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 1.5rem;

  .press-mentions-title {
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: 300;
    margin-bottom: 1rem;
    opacity: 0.7;
    letter-spacing: 0.05em;
  }

  .press-mentions-list {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  .event-press-item {
    display: flex;
    flex-direction: column;
    font-size: 0.75rem;
    padding: 1rem;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 4px;
    transition: all 0.2s ease;
    cursor: pointer;
    width: 100%;
    text-decoration: none;
    color: inherit;
    border: 1px solid rgba(255, 255, 255, 0.1);

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
      border-color: rgba(255, 255, 255, 0.2);
    }

    .press-item-date {
      font-family: var(--mono-font);
      font-size: 0.65rem;
      opacity: 0.6;
      margin-bottom: 0.3rem;
    }

    .press-item-source {
      font-weight: 600;
      text-transform: uppercase;
      font-size: 0.65rem;
      margin-bottom: 0.2rem;
    }

    .press-item-title {
      font-weight: 300;
      line-height: 1.4;
    }
  }
}

@media (min-width: 768px) {
  .event-press-mentions {
    .press-mentions-list {
      flex-direction: column; // Keep items in a single column on all screen sizes
    }

    .event-press-item {
      width: 100%; // Full width on all screen sizes
    }
  }
}

