// Font face declarations
@font-face {
  font-family: 'Commit Mono';
  src: url('../fonts/CommitMono VariableFont.woff2') format('woff2-variations');
  font-weight: 325;
  font-style: normal;
  font-display: swap;
  font-variation-settings:
    'wght' 325,
    'slnt' 0;
  font-feature-settings:
    'ss01' on,
    'ss02' on,
    'ss03' on,
    'ss04' on,
    'ss05' on,
    'cv01' on,
    'cv02' on,
    'cv03' on,
    'cv05' on,
    'cv10' on;
}

@font-face {
  font-family: 'Ogg';
  src: url('../fonts/Ogg-Regular.woff2') format('woff2'),
       url('../fonts/Ogg-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// CSS Custom Properties
:root {
  --mono-font: 'Commit Mono', monospace;
  --vh: 1vh;
}

// Colors
$color-dark: #091115;
$color-white: #FFFFFF;
$color-black: #0a0a0a;
$color-gray: #6b7280;

// Border radius
$border-radius: 4px;

// Project theme colors
$color-theme-aops: #091115;
$color-theme-des-nachtmahr: #584B39;
$color-theme-alluvial: #AB765A;
$background-color: $color-dark;

// Typography
@mixin font-tiny {
  font-family: var(--mono-font);
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 120%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
}

@mixin font-small {
  font-family: var(--mono-font);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}

@mixin font-medium {
  font-family: var(--mono-font);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.4;
  letter-spacing: 0.06em;
  text-transform: uppercase;
}

@mixin font-large {
  font-family: var(--mono-font);
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 1.4;
  letter-spacing: 0.06em;
  text-transform: uppercase;
}

// Only used in splash page logo
@mixin font-logo {
  font-family: 'Ogg', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  letter-spacing: 0.04em;
}
