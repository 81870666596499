@import 'styles/variables';

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 32px;
  height: 76px;
  background-color: transparent;
  z-index: 20;

  @media (max-width: 768px) {
    padding: 8px 16px;
  }
}

.header-logo-container {
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba($color-white, 0.1);
  }
}

.header-logo {
  width: 2rem;
  height: 2rem;
}

.header-project-menu {
  position: relative;
  cursor: pointer;
  margin-left: auto;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: 10px;
    background: transparent;
  }
}

.current-project {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 12px;
  background: transparent;
  transition: all 0.3s ease;
  text-align: right;
  min-height: 34px;
  width: 100%;
  cursor: pointer;
  border: none;
  white-space: nowrap;

  &:hover {
    background: rgba($color-white, 0.05);
  }

  .menu-indicator {
    margin-left: 12px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    opacity: 0.5;
    transition: opacity 0.3s ease;
    padding: 2px;

    .dot {
      width: 16px;
      height: 2px;
      background-color: $color-white;
      border-radius: 1px;
      transition: opacity 0.3s ease;
      opacity: 0.3;

      &.active {
        opacity: 1;
      }
    }
  }

  &:hover .menu-indicator {
    opacity: 0.8;
  }
}

.project-options {
  position: absolute;
  top: calc(100% - 2px);
  right: 0;
  background: transparent;
  backdrop-filter: blur(8px);
  padding: 2px;
  min-width: 100%;
  overflow: hidden;
  transition: all 0.3s ease;
  max-height: 0;
  opacity: 0;
  transform: translateY(-10px);
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 10;

  &.open {
    max-height: 80vh;
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
    overflow-y: auto;
  }
}

.project-option {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  padding: 8px 12px;
  transition: all 0.3s ease;
  text-align: right;
  background: transparent;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  width: 100%;
  min-width: fit-content;

  &:hover {
    background: rgba($color-white, 0.05);
  }

  &:focus {
    background: rgba($color-white, 0.05);
    outline: none;
  }
}

.project-name,
.project-season {
  @include font-tiny;
  color: $color-white;

  @media (min-width: 769px) {
    @include font-small;
  }
}

.project-season {
  @include font-tiny;
  color: $color-white;
  opacity: 0.8;
}

.project-separator {
  margin: 0 4px;
  color: $color-white;
  opacity: 0.5;
}

.back-to-timeline {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5em;
  padding: 8px 12px;
  background: transparent;
  border: none;
  cursor: pointer;
  color: $color-white;
  font-family: var(--mono-font);
  font-size: 0.8em;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  opacity: 0.7;
  transition: all 0.3s ease;
  margin-left: auto;
  min-width: 150px;
  text-align: right;

  &:hover {
    opacity: 1;
    background-color: rgba($color-white, 0.05);
  }

  &::before {
    content: '←';
    font-size: 1.2em;
    line-height: 1;
    order: 1;
    margin-left: 0.5em;
  }
}

.chevron {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  margin-right: 8px;
  transition: transform 0.3s ease;
  font-size: 14px;
  color: $color-white;
  opacity: 0.5;

  &.expanded {
    transform: rotate(90deg);
  }
}
