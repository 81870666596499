@import 'styles/variables';

.dynamic-image-highlight {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  overflow: visible;
  transition: opacity 0.3s ease-out;

  &.fade-out {
    opacity: 0;
  }

  &.animate {
    .frame-rectangle { animation: drawFrame 1s ease-out forwards; }
    .frame-label { animation: fadeIn 0.5s ease-out 0.5s forwards; }
    .node-line { animation: drawLine 1s ease-out forwards; }
    .node-label,
    .node-point { animation: fadeIn 0.5s ease-out forwards; }
  }

  --theme-color: var(--project-theme-color);
}

.gallery-image {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  object-fit: cover;
  outline: 1px solid var(--project-theme-color);
  outline-offset: -1px;
}

.frame-rectangle {
  position: absolute;
  border: 0.266667px solid $color-white;
  pointer-events: none;
  filter: drop-shadow(0px 0.523999px 5.76399px rgba(131, 213, 233, 0.25));
}

.frame-label {
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translate(0, 100%);
  padding: 4px;
  background: $color-white;
  color: var(--theme-color);
  z-index: 20;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;

  .frame-text {
    font-size: 1em;
    white-space: nowrap;
    color: var(--theme-color);
  }

  .frame-status {
    font-size: 0.7em;
    color: var(--theme-color);
    opacity: 0.7;
    white-space: nowrap;

    &::before {
      content: '//';
      margin-right: 4px;
    }
  }
}

.node-label {
  position: absolute;
  @include font-tiny;
  color: $color-white;
  opacity: 0;
  border: 0.25px solid $color-white;
  padding: 2px 4px;
  animation: fadeIn 0.3s ease-out forwards;
  background-color: rgba($color-dark, 0.1);
  backdrop-filter: blur(4px);
  white-space: normal;
  z-index: 10;
  max-width: 200px;
  overflow: auto;

  &.left-side {
    transform: translate(-5%, -50%);
    text-align: right;
  }

  &.right-side {
    transform: translate(-80%, -50%);
    text-align: left;
  }
}

.node-line {
  stroke: $color-white;
  stroke-width: 0.5;
  fill: none;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: drawLine 1s ease-out forwards;
  stroke-linecap: round; // Add rounded ends to the line
  stroke-linejoin: round; // Add rounded corners where the line bends
}

.node-point {
  position: absolute;
  width: 3px;
  height: 3px;
  background-color: $color-white;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  animation: fadeIn 0.3s ease-out forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes drawLine {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes drawFrame {
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
}
