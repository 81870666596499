@import 'styles/variables';
@import 'styles/common';

// Override heading styles
h1, h2, h3, h4, h5, h6 {
  line-height: 120% !important;
}

.sustainability-page {
  width: 100%;
  height: 100%;
  background-color: #091115;
  color: rgba(255, 255, 255, 0.7);
  padding: 2rem 0;
  box-sizing: border-box;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding: 1.5rem 0;
  }
}

.sustainability-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;

  @media (max-width: 768px) {
    padding: 0 1rem;
  }
}

.sustainability-frame {
  h1 {
    font-family: var(--mono-font);
    font-size: 1.5rem;
    margin-bottom: 2.5rem;
    color: $color-white;
    font-weight: normal;
    letter-spacing: -0.02em;

    @media (max-width: 768px) {
      font-size: 1.25rem;
      margin-bottom: 2rem;
    }
  }
}

.section {
  margin-bottom: 5rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.section-title {
  font-family: var(--mono-font);
  font-size: 1.25rem;
  margin-bottom: 2rem;
  color: $color-white;
  font-weight: normal;
  letter-spacing: -0.02em;

  @media (max-width: 768px) {
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
  }
}

.section-text {
  font-size: 0.875rem;
  line-height: 1.6;
  margin-bottom: 3rem;
  max-width: 800px;
  letter-spacing: -0.01em;
  color: rgba(255, 255, 255, 0.7);

  @media (max-width: 768px) {
    font-size: 0.875rem;
    margin-bottom: 2rem;
  }
}

.subsections {
  display: grid;
  gap: 4rem;
}

.subsection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  align-items: stretch;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  &:nth-child(even) {
    .subsection-content {
      order: 2;
    }
    .image-gallery {
      order: 1;
    }

    @media (max-width: 1024px) {
      .subsection-content,
      .image-gallery {
        order: 0;
      }
    }
  }
}

.subsection-content {
  padding: 2rem;
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(20px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: $border-radius;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 1.5rem;
  }
}

.subsection-title {
  font-family: var(--mono-font);
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
  color: $color-white;
  font-weight: normal;
  letter-spacing: -0.01em;

  @media (max-width: 768px) {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
}

.subsection-text {
  font-size: 0.875rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  letter-spacing: -0.01em;
  color: rgba(255, 255, 255, 0.7);

  &:last-child {
    margin-bottom: 0;
  }
}

.source {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
  font-family: var(--mono-font);
  margin-top: 1.5rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);

  .source-label {
    font-weight: bold;
    margin-right: 0.5rem;
  }
}

.image-gallery {
  display: grid;
  gap: 1.5rem;
  height: 100%;

  .gallery-item {
    position: relative;
    width: 100%;
    aspect-ratio: 3/4;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.03);
    border-radius: $border-radius;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: $border-radius;
    }

    .image-caption {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 1rem;
      background: rgba(0, 0, 0, 0.75);
      color: rgba(255, 255, 255, 0.8);
      font-size: 0.85rem;
      font-family: var(--mono-font);
      letter-spacing: -0.01em;
      transform: none;
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    &:hover {
      .image-caption {
        opacity: 1;
      }
    }
  }

  &:has(.gallery-item:nth-child(2)) {
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
}

// Clean up unused styles
.mission-info,
.practices-info,
.materials-info,
.custom-work-info,
.certifications-info,
.goals-info {
  p {
    margin-bottom: 1.5rem;
    line-height: 1.6;
  }
}

// Subsection headers (like Materials Reuse, Handmade Production)
.subsection-header {
  font-size: 1.3em;
  font-weight: normal;
  margin: 1.5rem 0 0.75rem;
  color: $color-white;
  font-family: var(--mono-font);
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

// Materials section
.materials-info {
  p {
    margin-bottom: 1.5rem;
    line-height: 1.6;
  }
}

.material-item {
  margin-bottom: 3rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 2rem;

  &:last-child {
    border-bottom: none;
  }

  .material-details {
    margin-bottom: 1.5rem;
  }

  .image-gallery {
    margin-top: 1rem;
  }
}

// Certifications section
.certifications-info {
  p {
    margin-bottom: 1.5rem;
    line-height: 1.6;
  }
}

.certification-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  padding-left: 1rem;

  @media (min-width: 769px) {
    flex-direction: row;
    border-left: none;
    padding-left: 0;
  }

  .certification-logo {
    width: 100%;
    max-width: 150px;
    margin-bottom: 1rem;

    @media (min-width: 769px) {
      margin-right: 1.5rem;
      margin-bottom: 0;
    }

    img {
      width: 100%;
      height: auto;
      border-radius: $border-radius;
    }
  }

  .certification-details {
    flex-grow: 1;

    .certification-name {
      margin-bottom: 0.5rem;
      font-size: 1.1em;
      font-weight: normal;
      color: $color-white;
      font-family: var(--mono-font);
      letter-spacing: 0.5px;
    }

    .certification-description {
      margin-bottom: 0.75rem;
      line-height: 1.6;
    }

    .certification-date {
      font-size: 0.9em;
      opacity: 0.8;

      .date-label {
        font-weight: bold;
      }
    }
  }
}

// Goals section
.goals-info {
  p {
    margin-bottom: 1.5rem;
    line-height: 1.6;
  }

  .goals-list {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-bottom: 1.5rem;

      h3 {
        margin-bottom: 0.5rem;
      }

      p {
        line-height: 1.6;
      }
    }
  }
}

// Image placeholders
.image-placeholder {
  width: 100%;
  height: 200px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: $border-radius;
  margin: 1.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed rgba(255, 255, 255, 0.2);

  .placeholder-text {
    color: rgba(255, 255, 255, 0.6);
    font-style: italic;
    font-family: var(--mono-font);
    letter-spacing: 0.5px;
  }
}

// Sustainability images
.sustainability-image {
  width: 100%;
  margin: 1.5rem 0;
  display: flex;
  flex-direction: column;
  border-radius: $border-radius;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    display: block;
    object-fit: contain;
    border-radius: $border-radius;
  }

  .image-caption {
    width: 100%;
    padding: 0.8rem 0.2rem;
    font-size: 0.9em;
    line-height: 1.4;
    text-align: center;
    color: rgba(255, 255, 255, 0.8);
    font-family: var(--mono-font);
    letter-spacing: 0.5px;
    margin-top: 0.5rem;
    text-transform: uppercase;
  }
}

// Links styling
.external-link {
  text-decoration: none;
  color: inherit;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.8;
  }
}

.info-line {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
  line-height: 1.5;
}

.info-key {
  min-width: 100px;
  opacity: 0.7;
}

.info-value {
  flex-grow: 1;
}