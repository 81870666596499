@import '../styles/variables';

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: inherit;
  color: white;
  font-family: var(--mono-font);
  letter-spacing: 0.5px;
}

.loading-text {
  font-size: 14px;
  margin-bottom: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;

  .dot-1, .dot-2, .dot-3 {
    opacity: 0;
    animation: dotFade 1.4s infinite;
  }

  .dot-2 {
    animation-delay: 0.2s;
  }

  .dot-3 {
    animation-delay: 0.4s;
  }
}

.loading-bar {
  width: 120px;
  height: 2px;
  background: rgba(255, 255, 255, 0.1);
  position: relative;
  overflow: hidden;
}

.loading-bar-progress {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 30%;
  background: white;
  animation: loadingProgress 1.4s infinite;
}

@keyframes dotFade {
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
}

@keyframes loadingProgress {
  0% {
    left: -30%;
  }
  100% {
    left: 100%;
  }
}